<!--
 * @Author: your name
 * @Date: 2020-12-04 19:38:01
 * @LastEditTime: 2021-01-26 22:41:58
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \one\src\views\submitsuccessfully\index.vue
-->
<template>
  <div class="submitsuccessfully" v-wechat-title="$route.meta.title">
    <navigation :msg="msg"></navigation>
    <div class="success">
      <img src="../../assets/selected.png" alt="" />
      <div class="box">
        <p>恭喜您，您已认证成功！</p>
        <p>请保持联系畅通，未来3个工作日内会随机抽检，<br />如无法联系到您，可能导致账号认证无效。</p>
      </div>
    </div>
    <button @click="okclick" class="bton bt">开始使用</button>
  </div>
</template>

<script>
export default {
  components: {
   
  },
  data() {
    return {
      msg: "提交成功",
    };
  },
  methods: {
    okclick() {
      this.$router.push("/home");
    },
  },
};
</script>

<style scoped>
p {
  line-height: 0.4rem;
}
.submitsuccessfully {
  background-color: #ffffff;
  padding-bottom: 2rem;
}
.navigation {
  position: relative;
}
.success {
  text-align: center;
}
.success img {
  width: 1.4rem;
  margin-top: 1.79rem;
  margin-bottom: 0.72rem;
}
.success p:first-child {
  color: #222222;
  font-size: 0.4rem;
  margin-bottom: 0.2rem;
  font-weight: 500;
}
.success p:nth-child(2) {
  font-size: 0.24rem;
  color: #f76e24;
}
.bt {
  margin-top: 3rem;
}
</style>